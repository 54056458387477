<template>
    <div id="pageTable">
        <v-container xl fluid>
            <v-overlay :value="overlay"  :z-index="999" >
                <div class="align-center justify-center text-center">
                    <v-progress-circular
                        indeterminate
                        :size="100"
                        :width="7"
                        color="#2DB9D1"
                    >
                        <v-img
                            src="/static/icon/favicon.ico"
                            :width="60"
                        ></v-img>
                    </v-progress-circular>
                    <p class="mt-5">{{fraseOverlay}}</p>
                </div>
                
            </v-overlay>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="center pb-0">
                    <div class="margen d-flex">
                        <hr class="vr" />
                        <p class="tblName">Detalle de paquetes de timbre</p>
                    </div>
                </v-col>
            </v-row>
            <v-row class="pl-5">
                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="">
                    <v-card class="elevation-0 border-card">
                        <v-container xl fluid style="overflow:auto" class="pa-10">
                            <div class="contenedor-card">
                                <h5 class="titulo-card">Datos de acceso</h5> 
                            </div>
                            <v-row class="ma-0 pa-0">
                                <table>
                                    <tr >
                                        <td rowspan="3" class="pr-2">
                                            <v-img
                                                src="static/icon/conf-timbres-datos.png"
                                                max-height="90"
                                                max-width="90"
                                                class="ma-2"
                                            >
                                            </v-img>
                                        </td>
                                        <td class="texto-estilo-gris pr-6">Email</td>
                                        <td class="texto-estilo-azul">{{datosLogin.email}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Perfil</td>
                                        <td class="texto-estilo-azul">{{rolComputed}}</td>
                                    </tr>
                                </table>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
                 <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="">
                    <v-card class="elevation-0 border-card">
                        <v-container xl fluid style="overflow:auto" class="pa-10">
                            <div class="contenedor-card">
                                <h5 class="titulo-card">Información general de timbres</h5> 
                            </div>
                            <v-row class="ma-0 pa-0">
                                <table>
                                    <tr >
                                        <td rowspan="4" class="pr-2">
                                            <v-img
                                                src="static/icon/conf-timbres-info.png"
                                                max-height="90"
                                                max-width="90"
                                                class="ma-2"
                                            >
                                            </v-img>
                                        </td>
                                        <td class="texto-estilo-gris pr-6">Comprados</td>
                                        <td class="texto-estilo-azul">{{configuracion.comprados}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Usados</td>
                                        <td class="texto-estilo-azul">{{configuracion.usados}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Disponibles</td>
                                        <td class="texto-estilo-azul">{{configuracion.disponibles}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Último movimiento</td>
                                        <td class="texto-estilo-azul">{{configuracion.ultimo_movimiento}}</td>
                                    </tr>
                                </table>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="pl-5">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="">
                    <v-card class="elevation-0 border-card">
                        <v-card-text style="overflow:auto" class="pa-10">
                            <div class="contenedor-card">
                                <h5 class="titulo-card">Datos del registro patronal</h5> 
                            </div>
                            <v-row class="ma-0 pa-0 pl-3">
                                <v-container
                                    class=""
                                    fluid
                                >
                                     <v-col cols="6">
                                        <v-card elevation="0">
                                           <v-img
                                                v-if="datosRP.logo != null"
                                                :src="imagenRP"
                                                class="ma-2"
                                                height="225"
                                                width="300"
                                                :aspect-ratio="1"
                                            ></v-img>
                                        </v-card>
                                    </v-col>
                                </v-container>
                                <table>
                                    <tr >
                                        <td class="texto-estilo-gris pr-6">Clave</td>
                                        <td class="texto-estilo-azul">{{datosRP.clave}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Nombre Registro Patronal</td>
                                        <td class="texto-estilo-azul">{{datosRP.nombre}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Registro Patronal</td>
                                        <td class="texto-estilo-azul">{{datosRP.registro_patronal}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">RFC</td>
                                        <td class="texto-estilo-azul">{{datosRP.rfc}}</td>
                                    </tr>
                                    <tr>
                                        <td class="texto-estilo-gris pr-6">Actividad comercial</td>
                                        <td class="texto-estilo-azul">{{datosRP.actividad_comercial}}</td>
                                    </tr>
                                </table>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="ml-5 mr-1 mt-10">
                <v-col cols="12" lg="12" class="pa-0">
                    <simple-table
                        ref="tabla"
                        :isSelectable="false"
                        :rows="datosTabla"
                        :columns="columns"
                        :perPage="[10, 25, 50, 100, 100]"
                        :tableName="'Historial de paquetes de timbre'"
                    >
                        <tbody slot="body" slot-scope="{ data }" class="tablaBody">
                            <tr :key="paquete.id" v-for="paquete in data" class="alto-tr">
                                <td>
                                    <div>
                                        {{paquete.clavePaquete}} - {{paquete.nombrePaquete}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{paquete.timbres_asignados}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        {{dateFormat(paquete.fecha_solicitud)}}
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div :class="classEstatus(paquete.estatus)">
                                            <span class="classEstatus">
                                                <img
                                                    v-if="paquete.estatus === 'Autorizado'"
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/aprovado.png"
                                                    alt="Empleado"
                                                />
                                                <img
                                                    v-else-if="paquete.estatus === 'Rechazado'"
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/cancelado.png"
                                                    alt="Empleado"
                                                />
                                                <img
                                                    v-else-if="paquete.estatus === 'EnProceso'"
                                                    style="height: 20px; width: 20px;  background-color: transparent;"
                                                    src="/static/icon/enProceso.png"
                                                    alt="Empleado"
                                                />
                                                &nbsp;&nbsp;&nbsp;
                                                {{paquete.estatus == 'EnProceso'? 'En Proceso': paquete.estatus}}

                                            </span>
                                        </div>
                                        
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <v-tooltip bottom v-if="rol == 'root'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on" 
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small 
                                                    @click="abrirModal(paquete)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="rol == 'root'">
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    v-on="on"
                                                    slot="activator"
                                                    class="botonHover"
                                                    v-if="paquete.url_comprobante"
                                                    icon
                                                    fab
                                                    dark
                                                    @click="descargar(paquete)"
                                                    small
                                                >
                                                    <v-icon
                                                        v-text="'$get_app_outline'"
                                                        class="iconoDelgadoBoton"
                                                    ></v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Descargar comprobante de transferencia</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </simple-table>
                </v-col>
            </v-row>
        </v-container>
        <template class="borde-card">
            <v-dialog v-model="dialog" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <img :src="imagen" class="imgModal" />
                                <h2 class="titleModal">{{ tituloModal }}</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="card_texto">
                            <div id="padre" v-show="isLoadingModal">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Estatus" rules="required">
                                            <v-autocomplete
                                                outlined
                                                :items="itemsEstatus"
                                                item-text="descripcion"
                                                item-value="valor"
                                                label="Estatus"
                                                persistent-hint
                                                v-model="estatusPaquete"
                                                :error-messages="errors"
                                                no-data-text="Datos no disponibles"
                                            />
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModal()" 
                                :disabled="isSaving"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardar()" 
                                :disabled="invalid" 
                                :loading="isSaving"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>
<script>
import Vue from 'vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import apiConfiguracionTimbres from '@/api/nominas/configurarTimbres';
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        'simple-table': DatatableSimple,
    },
    data(){
        return {
            datosLogin              : null,
            rol                     : null,
            isLoading               : false,
            loadingVista            : false,
            idRegistroPatronal      : null,
            isLoadingModal          : false,
            dialog                  : false,
            datosRP                     : {
                id                      : null,
                clave                   : null,
                nombre                  : null,
                registro_patronal       : null,
                calle                   : null,
                numero_interior         : null,
                numero_exterior         : null,
                rfc                     : null,
                logo                    : null,
                actividad_comercial     : null,
            },
            imagenRP                    : null,

            configuracion           : {
                comprados           : 0,
                usados              : 0,
                disponibles         : 0,
                ultimo_movimiento   : "",
            },
            isSaving            : false,

            datosTabla          : [],
            columns             : [
                {
                    label: 'Nombre del paquete',
                    name: 'registro_patronal_id',
                    filterable: false
                },
                {
                    label:'Cantidad de timbres',
                    name:'catidad_timbres',
                    filterable: false
                },
                {
                    label: 'Fecha de solicitud',
                    name: 'fecha_solicitud',
                    filterable: false
                },
                {
                    label: 'Estatus',
                    name: 'estatus',
                    filterable: false
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    filterable: false
                }
            ],
            itemsEstatus    : [
                { valor: 'Autorizado', descripcion: 'Autorizado' },
                { valor: 'Rechazado', descripcion: 'Rechazado' },
                /* { valor: 'EnProceso', descripcion: 'En Proceso' } */
            ],
            estatusPaquete  : null,
            paqueteId       : null,
            imagen          : null,
            tituloModal     : null,
            filters         : {},
            tableData       : {data:[]},
            itemsLinks      : [
                {
                    text: 'Configuración de timbres',
                    disabled: false,
                    to: 'configurarTimbres',
                },
                {
                    text: 'Detalle de paquetes de timbre',
                    disabled: true,
                    to: ''
                },
            ],
            overlay: false,
            fraseOverlay: "Descargando ..."
        }
    },
    watch:{
        
        
    },
    computed:{
        rolComputed(){
            if(this.rol == 'root') return 'Root';
            else if(this.rol == 'admin') return 'Administrador';
            else if(this.rol == 'admin-empresa') return 'Administrador empresa';
            else if(this.rol == 'admin-sucursal') return 'Administrador sucursal';
        }
    },
    methods:{
        classEstatus(estatus){
            if (estatus == "Autorizado") return "classAutorizado";
            if (estatus == "Rechazado") return "classRechazado";
            if (estatus == "EnProceso") return "classProceso";

        },
        getConfiguracion(){
            this.loadingVista = true;
            apiConfiguracionTimbres.getDetalleConfiguracionTimbre(this.idRegistroPatronal).then((response) => {
                this.loadingVista = false;
                this.datosTabla = response.data.historico;
                this.configuracion.comprados = response.data.comprados
                this.configuracion.usados = response.data.usados
                this.configuracion.disponibles = response.data.disponibles;
                this.configuracion.ultimo_movimiento = response.data.ultimo;
                this.datosRP = response.data;
                this.imagenRP = GetUrlImg.getURL() + response.data.logo;
            })
            .catch(err => {
                this.loadingVista = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },

        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato AAAA-MM-DD => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato AAAA-MM-DD
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha) {
            if(fecha == 'null' || fecha == ""){
                return
            }

            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        abrirModal(data = null){
            this.isLoadingModal = true;
            this.tituloModal        = "Actualizar paquete";
            this.imagen             = "/static/modal/brigadasUpdate.svg";
            this.isLoadingModal  = false;
            this.dialog =  true;
            this.paqueteId = data.id
            if(data.estatus === 'EnProceso'){
                this.estatusPaquete = null;
            }
            else{
                this.estatusPaquete = data.estatus;
            }
        },
        cerrarModal(){
            this.dialog = false;
            this.paqueteId  = null;
            this.estatusPaquete = null;
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        guardar(){
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }

                let parametros = {
                    paquete_id : this.paqueteId,
                    accion : this.estatusPaquete
                }
                apiConfiguracionTimbres.actualizarEstatusCompra(parametros).then(response => {
                    Notify.Success("Paquete Actualizado", "El paquete ha sido satisfactoriamente.");
                    this.cerrarModal();
                    this.getConfiguracion();
                    this.isSaving = false;
                })
                .catch(err => {
                    this.isSaving = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });

            });
        },
        async descargar(paquete){
            let params = { 
                paquete_id: paquete.id
            };
            let self = this;
            const FileDownload = require("js-file-download");
            let url = "configuracion-timbres/comprobante";
            let nombre_archivo = "comprobante.";
            self.overlay = true;
            try {
                await Vue.axios({
                        method: "POST",
                        responseType: "blob",
                        url: url,
                        content_type:"aplication/json",
                        headers: {
                            "content-type": "application/json",
                        },
                        data: params,
                    }).then((response) => {
                        let extensionArchivo = response.headers["content-type"].split("||");
                        FileDownload(response.data, nombre_archivo+extensionArchivo[1]);
                        self.overlay = false;
                    }).catch(async (error)=> {
                        self.overlay = false;
                        let errorString = error.response.data;
                        if (
                            error.request.responseType === 'blob' &&
                            error.response.data instanceof Blob &&
                            error.response.data.type &&
                            error.response.data.type.toLowerCase().indexOf('json') != -1
                        ){
                            errorString = JSON.parse(await error.response.data.text());
                            console.log(errorString)

                            if(typeof errorString.error === 'string'){
                                Notify.ErrorToast(errorString.error);
                            }else{
                                let error = Object.values(errorString.error);
                                let leyenda = ``;

                                for (var i = 0; i < error.length; i++) {
                                    leyenda+= `* ` + error[i] + `\n`;
                                }
                                Notify.ErrorToast(leyenda);
                            }
                        }; 

                    });
                } catch (err) {
                    self.overlay = false;
                    console.log(err)
                    Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico");
                }
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.idRegistroPatronal = this.$session.get('idRegistroPatronalTimbre');
        
    },
    mounted(){
        this.getConfiguracion();
        
    },
    updated(){

    },
    
}
</script>
<style scoped>
    .margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}
    .border-card {
        border-radius: 1.25rem;
    }
    .contenedor-card{
        padding: .625rem; /* 10px */
    }
    .titulo-card {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.125rem; /* 18px */
        line-height: 1.375rem; /* 22px */
        color: #444975;
    }
    .texto-estilo-gris{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: .875rem; /* 14px */
        line-height: 1.0625rem; /* 17px */
        color: #828282;
    }
    .texto-estilo-azul{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: .875rem;
        line-height: 1.0625rem;
        color: #444975;
    }
    .texto-titulo {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1E2144;
    }
    .btnGeneral {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }
    .divider-gray{
        border-bottom: 1px solid #BDBDBD;
    }
    .td01{
        font-family: 'Montserrat';
        font-style: bold;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #1E2144;
    }
    .td02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
    }

    .classAutorizado{
        color: #6FCF97;
        border: 1px solid #6FCF97;
        border-radius: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classRechazado {
        color: #D93025;
        border: 1px solid #D93025;
        border-radius: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classProceso {
        color: #E6B216;
        border: 1px solid #E6B216;
        border-radius: 50px;
        width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .classEstatus {
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>